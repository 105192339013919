<template>
  <b-container>
    <b-alert
      class="align-self-center"
      :variant="alert_state"
      :show="alert && alert.length > 0"
    >
      <span>{{ alert }}</span>
    </b-alert>
    <b-input-group size="sm">
      <b-form-input
        autofocus
        id="filter-input"
        v-model="filter"
        type="search"
        placeholder="Tìm kiếm"
      ></b-form-input>
    </b-input-group>
    <b-row>
      <b-col>
        <b-table
          sort-by="action_time"
          :sort-desc="true"
          :filter="filter"
          hover
          :items="logDetails"
          :fields="fields"
        >
          <template #cell(update)="row">
            <b-button @click="updateLogToErp(row)">
              <span v-if="uploadingRow && row.index == uploadingRow.index">
                <b-spinner small></b-spinner>
              </span>
              <span v-else>Cập nhật log</span>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { postLog } from "../api/logServices";
export default {
  name: "Log",
  data() {
    return {
      logs: [],
      filter: "",
      alert_state: null,
      alert: null,
      uploadingRow: null,
      fields: [
        {
          key: "ticket_code",
          label: "Mã phiếu",
        },
        {
          key: "product_name",
          label: "Tên sản phẩm",
        },
        {
          key: "updated_by",
          label: "Cập nhật bởi",
        },
        {
          key: "action_time",
          label: "Thời gian cập nhật",
          sortable: true,
        },
        {
          key: "change_from",
          label: "Số lượng cũ",
        },
        {
          key: "change_to",
          label: "Số lượng mới",
        },
        {
          key: "update",
          label: "Hành động",
        },
      ],
    };
  },
  created() {
    if (localStorage.getItem("log")) {
      this.logs = JSON.parse(localStorage.getItem("log"));
    }
  },
  methods: {
    clearAlert() {
      this.alert_state = null;
      this.alert = null;
    },
    updateLogToErp(row) {
      // console.log(row);
      this.clearAlert();
      this.uploadingRow = row;
      postLog(row.item)
        .then((response) => {
          this.uploadingRow = null;
          console.log(response);
          if (response.status === 200) {
            this.alert_state = "success";
            this.alert = response.data.message;
            this.logs.splice(row.index, 1);
            localStorage.setItem("log", JSON.stringify(this.logs));
          }
        })
        .catch((error) => {
          if (!error.status) {
            this.alert_state = "danger";
            this.alert = "Network Error!";
            console.log("Network error");
          }
          this.uploadingRow = null;
          this.alert_state = "danger";
          if (error.response) {
            if (error.response.data.errorCode)
              this.alert = error.response.data.errorCode;
          }
        });
    },
  },
  computed: {
    logDetails() {
      //   console.log(this.logs[0]);
      return this.logs.map((log) => log.detail);
    },
  },
};
</script>

<style scoped>
</style>